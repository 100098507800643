
import LoginProfileButtons from "@/components/auth/LoginProfileButtons"
import Button from "@/components/base/Button"
import {
    routeToArbeitgeberRegistration,
    routeToMegabat,
} from "@/helpers/routing"
import { mapGetters } from "vuex"
import { mdiClose, mdiChevronLeft, mdiMenu } from "@mdi/js"

export default {
    name: "RegionToolbar",
    components: { LoginProfileButtons, Button },
    props: {
        outside: {
            type: Boolean,
            default: true,
        },
        noSearch: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        persistentMobile: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        mdiClose,
        mdiMenu,
        mdiChevronLeft,
        scrollDistance: 0,
        scrollOffset: 330,
        scrolled: false,
    }),
    computed: {
        ...mapGetters("search", {
            isSearchActive: "active",
            searchLocation: "location",
            route: "route",
            channel: "channel",
            selectedCategory: "selectedCategory",
        }),
        ...mapGetters("meta", ["meta"]),
        ...mapGetters("routing", ["previousSearchRoute"]),
        ...mapGetters("school", ["name"]),
        transparent() {
            return (
                !this.scrolled &&
                !(this.meta.toolbar?.persistent || this.outside)
            )
        },
        backgroundStyle() {
            if (!this.transparent) {
                return "background: #fff !important"
            }
            return "background: #0000 !important"
        },
        showToolbar() {
            return this.$route.name != "region-slug"
        },
        routeToArbeitgeberRegistration() {
            return routeToArbeitgeberRegistration({ channel: this.channel })
        },
        routeToOverview() {
            return routeToMegabat({ slug: this.$route.params.slug })
        },
    },
    mounted() {
        window && window.addEventListener("scroll", this.handleScroll)
    },
    destroyed() {
        window && window.removeEventListener("scroll", this.handleScroll)
    },
    methods: {
        handleScroll() {
            if (window) {
                if (window.scrollY < this.scrollOffset) {
                    if (this.scrolled === true) {
                        this.scrolled = false
                    }
                } else if (this.scrolled === false) {
                    this.scrolled = true
                }
            }
        },
        showSideNavigation() {
            this.$emit("drawer-toggle")
            this.$store.dispatch("navigation/updateSidebar", true)
        },
    },
}
