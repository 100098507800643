export default {
    computed: {
        targetYear() {
            const curDate = new Date()
            const curYear = curDate.getFullYear()
            const nextYear = parseInt(curYear) + 1
            if (curDate.getMonth() >= 8) {
                return curYear + "/" + nextYear
            } else {
                return curYear
            }
        },
        currentYear() {
            return new Date().getFullYear()
        },
    },
}
